import React from 'react';
import styled from 'styled-components';
import theme from '../ui/theme';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import { Link, useRouter } from 'found';
import { DateTime } from 'luxon';
import { getTimeFromISO } from '../utils/luxon';
import { Column } from '../ui/FlexGrid';
import { AlignVerticalCenter } from '@mui/icons-material';
import { hasPerm } from '../utils';

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  & + & {
    border-top: 1px solid ${theme.grey5};
  }

  & > div {
    padding: ${theme.thin} 0;
  }
`;

const State = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GridTwoCols = styled.div`
  display: grid;
  grid-template-columns: 30% 65%;
  gap: 5%;
`;

const AlignCenter = styled.div`
  align-content: center;
  color: #6c757d;
`;

export default function History({ bilans, currentUser, optionalPath = '' }) {
  const { match } = useRouter();
  const interventionId = encodeURIComponent(match.params.interventionId);
  const bilanId = match.params.bilanId;

  const localized = (bilan) => DateTime.fromISO(bilan.created_at).setLocale('fr-fr').toLocaleString(DateTime.DATETIME_SHORT);

  const getAuthorId = (bilan) => {
    if (currentUser.organization_id === bilan.author.organization_id) {
      if (bilan.author.full_name.length > 0) return `${bilan.author.full_name} (${bilan.author.organization.name})`;
    } else {
      if (bilan.author.matricule && bilan.author.matricule.length > 0) return `${bilan.author.matricule} (${bilan.author.organization.name})`;
      return `${bilan.author.uid} (${bilan.author.organization.name})`;
    }
    ;
  };

  return (
    <>
      <List>
        {bilans.map((bilan, idx) => (
          <ListItem key={idx}>
            <div>
              <State>N° {bilans.length - idx} | {idx === bilans.length - 1 ? 'Original' : 'Mise à jour'}{bilanId == bilan.id ?
                <VisibilityOutlinedIcon style={{ color: theme.blue }} /> : ''}</State>
              <div width={3}>
                <Link
                  to={`/interventions/${optionalPath !== '' ? `${optionalPath}/` : ''}${interventionId}/bilans/${encodeURIComponent(bilan.id)}`}
                  data-sentry-id='history-elem'
                >
                  {localized(bilan)}
                </Link>
              </div>
              <div width={6}>
                {getAuthorId(bilan)}
              </div>
            </div>
          </ListItem>
        ))}
      </List>
      {hasPerm(currentUser, "web:intervention:historique-destination:show") && optionalPath !== 'sso-smp' && bilans[0].ch_history.length > 0 && (<>
        <h3 style={{ marginTop: '2rem' }}>Historique des destinations</h3>
        <hr />
        <List>
          {bilans[0].ch_history.map((h, idx) => (
            <ListItem key={idx}>
              <GridTwoCols>
                <AlignCenter>{h.suggestion ? 'Suggestion' : 'Décision'}</AlignCenter>
                <div>
                  <State>{getTimeFromISO(h.date, 'DATETIME_SHORT')}</State>
                  <div>{h.author}</div>
                  <div>{h.destination}</div>
                </div>
              </GridTwoCols>
            </ListItem>
          ))}
        </List>
      </>)}
    </>
  );
}
