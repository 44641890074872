import { Redirect, Route } from 'found';
import React from 'react';
import { hasLegacyPerm } from '../utils';
import { utils as syoUtils } from '@nfsave/syo-bilan';

import Roles from './screens/Roles';
import Organization from './screens/Organization';
import Layout from './Layout';
import NewRole from './screens/NewRole';
import EditRole from './screens/EditRole';
import Motives from './screens/Motives';
import OrganizationalUnits from './screens/OrganizationalUnits';
import NewOrganizationalUnit from './screens/NewOrganizationalUnit';
import EditOrganizationalUnit from './screens/EditOrganizationalUnit';
import MedicationsList from './screens/MedicationsList';
import NursingProtocolsList from './screens/NursingProtocolsList';
import Vehicles from './screens/Vehicles';
import RelevantFacts from './screens/RelevantFacts.';
import DuplicateRole from './screens/DuplicateRole';
import ParameterizedLists from './screens/ParameterizedLists';
import Injections from './screens/Injections';
import CreateOrUpdateInjection from './screens/CreateOrUpdateInjection';
import Protocols from './screens/Protocols';
import NewProtocol from './screens/CreateOrUpdateProtocol';
import VitalSignBounds from './screens/VitalSignBounds';
import CreateOrUpdateVitalSignBound from './screens/CreateOrUpdateVitalSignBound';
import Units from "./screens/Units";
import DrugRoutes from "./screens/DrugRoutes";

export const routes = (
  <Route
    path='gestionnaire'
    Component={Layout}
    getData={data => {
      if (!hasLegacyPerm(data.context, 'manage:organization')) data.router.replace('/droits-insuffisant');
    }}
  >
    <Redirect from='/' to='/gestionnaire/organisation' />

    <Route path='organisation' Component={Organization} />

    <Route
      path='roles'
      getData={data => {
        if (syoUtils.hasPerm(data.context, 'manage:roles:hide')) data.router.replace('/droits-insuffisant');
      }}
    >
      <Route path='/' Component={Roles} />
      <Route path='new' Component={NewRole} />
      <Route path=':roleId/dupliquer' Component={DuplicateRole} />
      <Route path=':roleId/modifier' Component={EditRole} />
    </Route>

    <Route
      path='motifs'
      Component={Motives}
      getData={data => {
        if (!syoUtils.hasPerm(data.context, 'manage:intervention-motives')) data.router.replace('/droits-insuffisant');
      }}
    />

    <Route path='unites-organisationnelles'>
      <Route path='/' Component={OrganizationalUnits} />
      <Route path='nouvelle' Component={NewOrganizationalUnit} />
      <Route path=':id/modifier' Component={EditOrganizationalUnit} />
    </Route>

    <Route
      path='protocols'
      getData={data => {
        if (!syoUtils.hasPerm(data.context, 'web:injections-and-protocols:manage'))
          data.router.replace('/droits-insuffisant');
      }}
    >
      <Route path='/' Component={Protocols} />
      <Route path='edit' Component={NewProtocol} />
    </Route>
    <Route
      path='injections'
      getData={data => {
        if (!syoUtils.hasPerm(data.context, 'web:injections-and-protocols:manage'))
          data.router.replace('/droits-insuffisant');
      }}
    >
      <Route path='/' Component={Injections} />
      <Route path='edit' Component={CreateOrUpdateInjection} />
    </Route>

    <Route
      path='units'
      Component={Units}
      getData={data => {
        if (!syoUtils.hasPerm(data.context, 'web:injections-and-protocols:manage')
          && !syoUtils.hasPerm(data.context, 'web:units-and-drug-routes:manage'))
          data.router.replace('/droits-insuffisant');
      }}
    />

    <Route
      path='drug-routes'
      Component={DrugRoutes}
      getData={data => {
        if (!syoUtils.hasPerm(data.context, 'web:injections-and-protocols:manage')
          && !syoUtils.hasPerm(data.context, 'web:units-and-drug-routes:manage'))
          data.router.replace('/droits-insuffisant');
      }}
    />

    <Route
      path='bounds'
      getData={data => {
        if (!syoUtils.hasPerm(data.context, 'web:bounds:manage')) data.router.replace('/droits-insuffisant');
      }}
    >
      <Route path='/' Component={VitalSignBounds} />
      <Route path='edit' Component={CreateOrUpdateVitalSignBound} />
    </Route>

    <Route
      path='medicaments'
      Component={MedicationsList}
      getData={data => {
        if (!syoUtils.hasPerm(data.context, 'medications:manage')) data.router.replace('/droits-insuffisant');
      }}
    />

    <Route
      path='pisu'
      Component={NursingProtocolsList}
      getData={data => {
        if (!syoUtils.hasPerm(data.context, 'nursing-protocols:manage')) data.router.replace('/droits-insuffisant');
      }}
    />

    <Route
      path='engins'
      Component={Vehicles}
      getData={data => {
        if (!syoUtils.hasPerm(data.context, 'manage:vehicles')) data.router.replace('/droits-insuffisant');
      }}
    />

    <Route
      path='faits-remarquables'
      Component={RelevantFacts}
      getData={data => {
        if (!syoUtils.hasPerm(data.context, 'manage:relevant-facts')) data.router.replace('/droits-insuffisant');
      }}
    />

    <Route
      path='listes-parametrees'
      Component={ParameterizedLists}
      getData={data => {
        if (!syoUtils.hasPerm(data.context, 'manage:parameterized-lists')) data.router.replace('/droits-insuffisant');
      }}
    />
  </Route>
);
