import React, { useContext, useEffect, useMemo, useState } from 'react';
import http from '../../http';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';
import { hasIndividualOrEquivalentPerm, INDIVIDUAL_PERMISSIONS, isAbortError } from '../../utils';
import CurrentUserContext from '../../CurrentUserContext';
import queryString from 'query-string';
import { data as syoData, SyoApi, utils as syoUtils } from '@nfsave/syo-bilan';

import List from '../List';
import PageHeader, { Title } from '../../ui/PageHeader';
import LoadingSpinner from '../../ui/LoadingSpinner';
import BoundedPagination from '../../ui/BoundedPagination';
import Button from '../../ui/Button';
import Label from '../../ui/Label';
import Input from '../../ui/Input';
import styled from 'styled-components';
import theme from '../../ui/theme';
import SearchBarCompo, { SearchBar } from '../../ui/SearchBar';
import Select, { ValueContainerLimitSelected as ValueContainer } from '../../ui/Select';
import LoaderBarContext from '../../ui/useLoaderBar';
import { SwitchDouble } from '../../ui/Switch';

const FilterBar = styled.form`
  width: 100%;

  div.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > * {
      padding-bottom: ${theme.small};
    }

    > *:not(:last-child) {
      padding-right: ${theme.small};
    }

    & .item-primary {
      width: 35%;
    }

    & .item-secondary {
      width: 30%;
    }
  }

  div.rowWrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > * {
      padding-bottom: ${theme.small};
    }

    > *:not(:last-child) {
      padding-right: ${theme.small};
    }

    > div {
      width: 25%;
    }
  }

  div.rowAction {
    display: flex;
    justify-content: flex-end;

    > button {
      margin-bottom: ${theme.small};
    }

    > button:first-child {
      margin-right: ${theme.small};
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;

    div.row {
      > *:not(:last-child) {
        padding-right: ${theme.small};
      }
    }

    div.rowWrap {
      > *:nth-child(even) {
        padding-right: 0;
      }

      > div {
        width: 50%;
      }
    }
  }

  @media (max-width: 680px) {
    div.row {
      flex-direction: column;

      > * {
        padding-right: 0 !important;
      }

      & .item-primary,
      & .item-secondary {
        width: 100%;
      }
    }

    div.rowWrap {
      > *:not(:last-child) {
        padding-right: 0;
      }

      > div {
        width: 100%;
      }
    }

    div.rowAction {
      flex-direction: column;

      > button {
        width: 100%;
      }

      > button:first-child {
        margin-right: 0;
      }
    }
  }
`;

const OPTIONS_CONSO = [
  { value: 1, label: 'Ceinture pelvienne', path: 'ceinture_pelvienne' },
  {
    value: 2,
    label: 'Compresses hémostatiques',
    path: 'compresses_hemostatiques',
  },
  {
    value: 3,
    label: 'Compresses hydrogel 10*10',
    path: 'compresses_hydrogel_10_10',
  },
  {
    value: 4,
    label: 'Compresses hydrogel 20*20',
    path: 'compresses_hydrogel_20_20',
  },
  { value: 5, label: 'Garrot', path: 'garrot' },
  { value: 6, label: 'Pansement compressif', path: 'pansement_compressif' },
];

function PharmacyCompliance({ match, router }) {
  const { currentUser } = useContext(CurrentUserContext);
  const { setLoaderBar } = useContext(LoaderBarContext);

  const controller = new AbortController();

  const {
    isLoading: payloadIsLoading,
    isFetching: payloadIsFetching,
    data: payload,
    refetch: refetchPayload,
  } = useQuery(
    ['archiveBilans'],
    async () => {
      setLoaderBar(true);
      const options = {};
      if (match.location.query.page) options.page = match.location.query.page;
      if (match.location.query.createdAfter) options.created_after = match.location.query.createdAfter;
      if (match.location.query.createdBefore) options.created_before = match.location.query.createdBefore;
      if (match.location.query.createdEntity) options.created_entity = match.location.query.createdEntity;
      if (match.location.query.vehicleNames) {
        if (Array.isArray(match.location.query.vehicleNames)) {
          options.vehicle_names = match.location.query.vehicleNames;
        } else if (typeof match.location.query.vehicleNames === 'string') {
          options.vehicle_names = [match.location.query.vehicleNames];
        }
      }
      if (match.location.query.pharmacyCompliance)
        options.pharmacy_compliance = match.location.query.pharmacyCompliance;
      if (match.location.query.firstResponderSerial)
        options.first_responder_serial = match.location.query.firstResponderSerial;
      if (match.location.query.medicalSerial) options.medical_serial = match.location.query.medicalSerial;
      if (match.location.query.authorId) options.author_id = match.location.query.authorId;
      if (match.location.query.dsa) options.dsa = match.location.query.dsa;

      if (match.location.query.injection) options.injection = match.location.query.injection;
      if (match.location.query.injection_not_in) options.injection_not_in = match.location.query.injection_not_in;

      if (match.location.query.only_with_prescriptions) options.only_with_prescriptions = match.location.query.only_with_prescriptions;
      if (match.location.query.prescriptions_schemas) options.prescriptions_schemas = match.location.query.prescriptions_schemas;
      if (match.location.query.prescriptions_schemas_not_in) options.prescriptions_schemas_not_in = match.location.query.prescriptions_schemas_not_in;

      if (match.location.query.only_with_injections === 'true') options.only_with_injections = true;
      // CONSOMMABLES
      const tempConso = [];
      OPTIONS_CONSO.forEach(optionConso => {
        if (match.location.query[optionConso.path]) {
          options[optionConso.path] = match.location.query[optionConso.path];
          tempConso.push(optionConso.value);
        }
      });
      setFilterConso([...tempConso]);

      const searchParamsString = queryString.stringify(options, {
        arrayFormat: 'bracket',
      });

      return await http
        .get(`bilans/archive`, {
          signal: controller.signal,
          timeout: 50000,
          searchParams: searchParamsString,
        })
        .json()
        .then(res => {
          return res;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          toast.warn(`Une erreur est survenue pendant la récupération des interventions.`, {
            toastId: 'archiveBilans',
          });
          Sentry.captureException(error);
          console.error(error);
        })
        .finally(() => {
          setLoaderBar(false);
        });
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const { isLoading: vehiclesIsLoading, data: vehicles } = useQuery(
    ['archiveBilans', 'vehicles'],
    async () => {
      return await http
        .get(`vehicles.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          const vehicles = res.data.sort((left, right) => {
            const lhs = (left.name || '').toUpperCase();
            const rhs = (right.name || '').toUpperCase();
            return lhs > rhs ? 1 : -1;
          });
          let temp = [];
          vehicles.map(v => {
            temp.push({
              value: v.name,
              label: v.name,
            });
          });
          return temp;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des engins');
          throw error;
        });
    },
    { cacheTime: 0 },
  );

  const { data: users, isLoading: usersIsLoading } = useQuery(
    ['archiveBilans', 'users'],
    async () => {
      return await http
        .get(`user`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          let temp = [];
          res.map(u => {
            temp.push({
              value: u.id,
              label: `${u.full_name}${u.matricule ? ` (${u.matricule})` : ''}${u.provider !== 'email' ? ' | AD' : ''}`,
            });
          });
          return temp.sort((left, right) => {
            const lhs = (left.label || '').toLowerCase();
            const rhs = (right.label || '').toLowerCase();
            return lhs > rhs ? 1 : -1;
          });
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des utilisateurs');
          throw error;
        });
    },
    {
      enabled:
        hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('USERS_ADD'), currentUser) ||
        syoUtils.hasPerm(currentUser, 'web:bilan:auteur_filtre'),
      cacheTime: 0,
    },
  );

  const { isLoading: medicationsIsLoading, data: medications } = useQuery(
    ['archiveBilans', 'medicationsList'],
    async () => {
      try {
        const temp = [];
        // MEDICATIONS
        const medications = await http
          .get(`medications.json`, {
            signal: controller.signal,
          })
          .json();
        medications.data.forEach(m => {
          temp.push({
            value: m.name,
            label: m.name,
          });
        });
        // SOLUTES
        if (syoUtils.hasPerm(currentUser, 'web:interventions-pui:listing:solutes-with-medications')) {
          const solutes = await http
            .get(`parameterized_lists.json`, {
              signal: controller.signal,
            })
            .json();
          const hasSolutes = solutes.data?.find(elem => elem.name === 'solutes' || elem.name === 'ft-solutes');
          hasSolutes === undefined
            ? currentUser.departement === 38
              ? syoData.solutesSdis38.forEach(s => {
                temp.push({
                  value: s,
                  label: s,
                });
              })
              : syoData.solutes.forEach(s => {
                temp.push({
                  value: s,
                  label: s,
                });
              })
            : hasSolutes.items.forEach(s => {
              temp.push({
                value: s.value,
                label: s.value,
              });
            });
        }
        // AUTRES
        temp.push({
          value: 'other',
          label: 'Autres',
        });

        return temp;
      } catch (error) {
        if (isAbortError(error)) return;
        console.error(error);
        Sentry.captureException(error);
        toast.warn('Une erreur est survenue lors de la récupération des médicaments');
        throw error;
      }
    },
    { cacheTime: 0 },
  );

  const { isLoading: prescriptionsSchemaIsLoading, data: prescriptionsSchema } = useQuery(
    ['prescriptionsChooseList'],
    async () => {
      if (!currentUser) return [];
      try {
        const res = await http
          .get(`organizations/${encodeURIComponent(currentUser.organization_id)}/prescription_schema.json`, {
            signal: controller.signal,
          })
          .json();
        res.schemas.forEach(it => (it.payloadParse = JSON.parse(it.payload)));
        let temp = res.schemas.map(it => {
          let value: string;
          if (it.prescription_type === 'GESTURE') value = it.payloadParse.geste;
          else value = it.payloadParse.dosage
            ? `${it.payloadParse.label} (${it.payloadParse.dosage} ${it.payloadParse.unit} ${it.payloadParse.route})`
            : `${it.payloadParse.label} (${it.payloadParse.route})`;
          return {
            value: value,
            label: value
          };
        });
        temp.push({
          value: 'other',
          label: 'Autres',
        });
        return temp
      } catch (error) {
        if (isAbortError(error)) return [];
        console.error(error);
        Sentry.captureException(error);
        toast.warn('Une erreur est survenue lors de la récupération de la liste des schémas de prescriptions');
        throw error;
      }
    },
    {
      cacheTime: 0,
      enabled: currentUser !== null,
    },
  );

  const [filterCreatedAfter, setFilterCreatedAfter] = useState(match.location.query.createdAfter || '');
  const [filterCreatedBefore, setFilterCreatedBefore] = useState(match.location.query.createdBefore || '');

  const [filterInjections, setFilterInjections] = useState(match.location.query.only_with_injections || false);
  const [filterMedication, setFilterMedication] = useState(match.location.query.injection || '');
  const [filterMedicationOthers, setFilterMedicationOthers] = useState(match.location.query.injection_not_in || []);

  const [filterPrescriptions, setFilterPrescriptions] = useState(match.location.query.only_with_prescriptions || false);
  const [filterPrescriptionSchema, setFilterPrescriptionSchema] = useState(match.location.query.prescriptions_schemas || '');
  const [filterPrescriptionSchemaOthers, setFilterPrescriptionSchemaOthers] = useState(match.location.query.prescriptions_schemas_not_in || []);

  const [filterSortBilan, setFilterSortBilan] = useState(match.location.query.createdEntity || 'bilans');
  const [filterInter, setFilterInter] = useState(match.location.query.firstResponderSerial || '');
  const [filterDRM, setFilterDRM] = useState(match.location.query.medicalSerial || '');
  const [filterConso, setFilterConso] = useState([]);
  const [filterVehicle, setFilterVehicle] = useState(match.location.query.vehicleNames || []);
  const [filterAuthorId, setFilterAuthorId] = useState(parseInt(match.location.query.authorId, 10) || '');
  const [filterCompliance, setFilterCompliance] = useState(parseInt(match.location.query.pharmacyCompliance, 10) || '');
  const [filterDsa, setFilterDsa] = useState(match.location.query.dsa || false);
  const currentPage = parseInt(match.location.query.page || '1', 10);

  const sortBilanOptions = [
    { value: 'bilans', label: 'Réception du bilan' },
    { value: 'interventions', label: 'Création du bilan' },
  ];

  useEffect(() => {
    refetchPayload();
  }, [match]);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const handleFormSubmit = ev => {
    ev.preventDefault();
    const options = {};
    options.createdEntity = filterSortBilan || 'bilans';
    if (filterCreatedAfter !== '') options.createdAfter = filterCreatedAfter;
    if (filterCreatedBefore !== '') options.createdBefore = filterCreatedBefore;
    if (filterVehicle !== '' && filterVehicle.length > 0) options.vehicleNames = filterVehicle;
    if (filterInter !== '') options.firstResponderSerial = filterInter;
    if (filterDRM !== '') options.medicalSerial = filterDRM;
    if (filterAuthorId !== '') options.authorId = filterAuthorId;
    if (filterCompliance !== '') options.pharmacyCompliance = filterCompliance;

    if (filterMedication !== '') options.injection = filterMedication;
    if (filterMedicationOthers.length > 0) options.injection_not_in = filterMedicationOthers;

    if (filterPrescriptions) options.only_with_prescriptions = filterPrescriptions.toString();
    if (filterPrescriptionSchema !== '') options.prescriptions_schemas = filterPrescriptionSchema;
    if (filterPrescriptionSchemaOthers.length > 0) options.prescriptions_schemas_not_in = filterPrescriptionSchemaOthers;

    if (filterDsa) options.dsa = 'y';
    options.only_with_injections = filterInjections.toString();
    // CONSOMMABLES
    if (filterConso.length > 0) {
      OPTIONS_CONSO.forEach(optionConso => {
        if (filterConso.includes(optionConso.value)) {
          options[optionConso.path] = 'y';
        }
      });
    }
    router.push({
      pathname: '/interventions/pui',
      query: {
        page: '1',
        ...options,
      },
    });
  };

  const handleFilterCreatedAfterChange = ev => {
    setFilterCreatedAfter(ev.target.value);
  };

  const handleFilterCreatedBeforeChange = ev => {
    setFilterCreatedBefore(ev.target.value);
  };

  const handleSortBilanChange = ev => {
    setFilterSortBilan(ev.value);
  };

  const handleFilterInterChange = ev => {
    setFilterInter(ev.target.value);
  };

  const handleFilterDRMChange = ev => {
    setFilterDRM(ev.target.value);
  };

  const handleFilterConsoChange = ev => {
    if (ev == null) {
      setFilterConso([]);
    } else {
      const c = Array.isArray(ev) ? ev.map(x => x.value) : [];
      setFilterConso(c);
    }
  };

  const handleFilterVehicleChange = ev => {
    if (ev == null) {
      setFilterVehicle([]);
    } else {
      const v = Array.isArray(ev) ? ev.map(x => x.label) : [];
      setFilterVehicle(v);
    }
  };

  const handleFilterAuthorChange = ev => {
    setFilterAuthorId(ev?.value || '');
  };

  const handleSelectAllVehicleType = text => {
    const temp = vehicles.filter(v => v.label.toLowerCase().includes(text.toLowerCase())).map(v => v.label);
    setFilterVehicle([...temp]);
  };

  const handleFilterComplianceChange = ev => {
    setFilterCompliance(ev?.value || '');
  };

  const handleFilterMedicationChange = ev => {
    if (!ev?.value) {
      setFilterMedicationOthers([]);
      setFilterMedication('');
    } else {
      if (ev.value === 'other') {
        setFilterMedicationOthers([...medications.map(m => m.value)]);
        setFilterMedication('');
      } else {
        setFilterMedicationOthers([]);
        setFilterMedication(ev?.value || '');
      }
    }
  };

  const handleFilterPrescriptionSchemaChange = ev => {
    if (!ev?.value) {
      setFilterPrescriptionSchemaOthers([]);
      setFilterPrescriptionSchema('');
      console.log(filterPrescriptionSchema)
      console.log(filterPrescriptionSchemaOthers)
    } else {
      if (ev.value === 'other') {
        setFilterPrescriptionSchemaOthers([...prescriptionsSchema.map(m => m.value)]);
        setFilterPrescriptionSchema('');
        console.log(filterPrescriptionSchema)
        console.log(filterPrescriptionSchemaOthers)
      } else {
        setFilterPrescriptionSchemaOthers([]);
        setFilterPrescriptionSchema(ev?.value || '');
        console.log(filterPrescriptionSchema)
        console.log(filterPrescriptionSchemaOthers)
      }
    }
  };

  const handleResetFilter = () => {
    setFilterInjections(true);
    setFilterCreatedAfter('');
    setFilterCreatedBefore('');
    setFilterSortBilan('bilans');
    setFilterInter('');
    setFilterDRM('');
    setFilterVehicle([]);
    setFilterAuthorId('');
    setFilterCompliance('');
    setFilterMedication('');
    setFilterMedicationOthers([]);
    setFilterPrescriptionSchema('');
    setFilterPrescriptionSchemaOthers([]);
    setFilterDsa(false);
    setFilterPrescriptions(false);
    setFilterConso([]);
  };

  const count = payload === null || payload === undefined ? 0 : Math.ceil(payload.total / payload.per_page);

  const goTo = p =>
    router.push({
      pathname: '/interventions/pui',
      query: {
        ...match.location.query,
        only_with_injections: filterInjections.toString(),
        page: p,
      },
    });

  const btnVehiclesFilter = useMemo(() => {
    if (vehicles) {
      return {
        vsm: vehicles.filter(v => v.label.toLowerCase().includes('vsm')).map(v => v.label),
        vls: vehicles.filter(v => v.label.toLowerCase().includes('vls')).map(v => v.label),
        vsav: vehicles.filter(v => v.label.toLowerCase().includes('vsav')).map(v => v.label),
      };
    }
    return null;
  }, [vehicles]);

  const pagination = (
    <BoundedPagination
      total={payload && payload.total}
      perPage={payload && payload.per_page}
      count={count}
      current={currentPage}
      onClick={goTo}
    />
  );

  const OPTIONS_COMPLIANCE_REMARQUE = [...syoData.PHARMACY_COMPLIANCE_REMARQUE, { value: -1, label: 'Non renseigné' }];

  const OPTIONS_COMPLIANCE_CONFORMITE = [
    ...syoData.PHARMACY_COMPLIANCE_CONFORMITE,
    { value: -1, label: 'Non renseigné' },
  ];

  return (
    <>
      <PageHeader>
        <Title>Interventions PUI</Title>
      </PageHeader>

      <SearchBarCompo>
        <SearchBar>
          <FilterBar onSubmit={handleFormSubmit}>
            <div className='rowWrap'>
              <div className='item-primary'>
                <Label htmlFor='filter-created-after-input'>
                  {filterSortBilan === 'bilans' ? 'Réceptionnées' : 'Créées'} à partir du (inclus)
                </Label>
                <Input
                  id='filter-created-after-input'
                  type='date'
                  value={filterCreatedAfter}
                  max={filterCreatedBefore}
                  onChange={handleFilterCreatedAfterChange}
                />
              </div>
              <div className='item-primary'>
                <Label htmlFor='filter-created-before-input'>
                  {filterSortBilan === 'bilans' ? 'Réceptionnées' : 'Créées'} jusqu'au (non inclus)
                </Label>
                <Input
                  id='filter-created-before-input'
                  type='date'
                  value={filterCreatedBefore}
                  min={filterCreatedAfter}
                  onChange={handleFilterCreatedBeforeChange}
                />
              </div>
              <div className='item-secondary'>
                <Label htmlFor='filter-bilans-input'>Filtrage par date de</Label>
                <Select
                  id='filter-bilans-input'
                  isSearchable={true}
                  isClearable={false}
                  options={sortBilanOptions}
                  value={sortBilanOptions.find(v => v.value === filterSortBilan) || null}
                  onChange={handleSortBilanChange}
                  placeholder=''
                />
              </div>
              <div>
                <Label htmlFor='filter-inter-inj'>Interventions uniquement avec injections</Label>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '38px',
                  }}
                >
                  <SwitchDouble
                    labels={['Non', 'Oui']}
                    switchValue={filterInjections}
                    onChange={() => setFilterInjections(!filterInjections)}
                  />
                </div>
              </div>
            </div>
            <div className='rowWrap'>
              <div>
                <Label htmlFor='filter-inter-input'>
                  {syoUtils.hasPerm(currentUser, 'web:interventions:primo-intervenant:to:numero-intervention')
                    ? 'Numéro d\'intervention'
                    : 'Numéro primo-intervenant'}
                </Label>
                <Input
                  id='filter-inter-input'
                  type='text'
                  value={filterInter}
                  onChange={handleFilterInterChange}
                  placeholder='Numéro complet'
                />
              </div>
              <div>
                <Label htmlFor='filter-drm-input'>Numéro de dossier DRM</Label>
                <Input
                  id='filter-drm-input'
                  type='text'
                  value={filterDRM}
                  onChange={handleFilterDRMChange}
                  placeholder='Numéro complet'
                />
              </div>
              <div>
                <Label htmlFor='filter-conformity'>
                  {syoUtils.hasPerm(currentUser, 'web:interventions_pui:label_conformite_en_remarque')
                    ? 'Remarque / Relecture'
                    : 'Conformité'}
                </Label>
                <Select
                  id='filter-conformity'
                  isSearchable={true}
                  isClearable={true}
                  options={
                    syoUtils.hasPerm(currentUser, 'web:interventions_pui:label_conformite_en_remarque')
                      ? OPTIONS_COMPLIANCE_REMARQUE
                      : OPTIONS_COMPLIANCE_CONFORMITE
                  }
                  value={
                    syoUtils.hasPerm(currentUser, 'web:interventions_pui:label_conformite_en_remarque')
                      ? OPTIONS_COMPLIANCE_REMARQUE.find(u => u.value === filterCompliance) || null
                      : OPTIONS_COMPLIANCE_CONFORMITE.find(u => u.value === filterCompliance) || null
                  }
                  onChange={handleFilterComplianceChange}
                  placeholder=''
                />
              </div>
              {!medicationsIsLoading && medications && (
                <div>
                  <Label htmlFor='filter-injection-input'>Thérapeutique injecté</Label>
                  <Select
                    id='filter-injection-input'
                    isSearchable={true}
                    isClearable={true}
                    options={medications}
                    value={
                      medications.find(u => u.value === filterMedication) ||
                      (filterMedicationOthers.length > 0 && medications.find(u => u.value === 'other')) ||
                      null
                    }
                    onChange={e => handleFilterMedicationChange(e)}
                    placeholder=''
                  />
                </div>
              )}
              <div>
                <Label htmlFor='filter-inter-inj'>Interventions uniquement avec usage d'un DSA</Label>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '38px',
                  }}
                >
                  <SwitchDouble
                    labels={['Non', 'Oui']}
                    switchValue={filterDsa}
                    onChange={() => setFilterDsa(!filterDsa)}
                  />
                </div>
              </div>
              {syoUtils.hasPerm(currentUser, 'web:interventions_pui:filtres:consommables-materiels') && (
                <div>
                  <Label htmlFor='filter-consommables-input'>Consommables / Matériels</Label>
                  <Select
                    id='filter-consommables-input'
                    isSearchable
                    isClearable
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={OPTIONS_CONSO}
                    value={OPTIONS_CONSO.filter(obj => filterConso.includes(obj.value))}
                    onChange={handleFilterConsoChange}
                    placeholder='Consommable(s) / Matériel(s)'
                    components={{
                      ValueContainer,
                    }}
                  />
                </div>
              )}
              {!vehiclesIsLoading && vehicles && vehicles.length > 0 && (
                <>
                  <div>
                    <Label htmlFor='filter-vehicles-input'>Véhicules</Label>
                    <Select
                      id='filter-vehicles-input'
                      isSearchable
                      isClearable
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      options={vehicles}
                      value={vehicles.filter(obj => filterVehicle.includes(obj.label))}
                      onChange={handleFilterVehicleChange}
                      placeholder='Véhicule(s)'
                      noOptionsMessage={() => 'Aucun véhicule'}
                      components={{
                        ValueContainer,
                      }}
                    />
                  </div>
                  {btnVehiclesFilter &&
                    (btnVehiclesFilter.vsm.length !== 0 ||
                      btnVehiclesFilter.vsav.length !== 0 ||
                      btnVehiclesFilter.vls.length !== 0) && (
                      <div>
                        <Label>Sélectionner tous les véhicules</Label>
                        <div>
                          {btnVehiclesFilter && btnVehiclesFilter.vsm.length > 0 && (
                            <Button
                              type='button'
                              style={{ marginRight: theme.thin }}
                              onClick={() => handleSelectAllVehicleType('vsm')}
                            >
                              VSM
                            </Button>
                          )}
                          {btnVehiclesFilter && btnVehiclesFilter.vsav.length > 0 && (
                            <Button
                              type='button'
                              style={{ marginRight: theme.thin }}
                              onClick={() => handleSelectAllVehicleType('vsav')}
                            >
                              VSAV
                            </Button>
                          )}
                          {btnVehiclesFilter && btnVehiclesFilter.vls.length > 0 && (
                            <Button
                              type='button'
                              style={{ marginRight: theme.thin }}
                              onClick={() => handleSelectAllVehicleType('vls')}
                            >
                              VLS
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                </>
              )}
              {!usersIsLoading && users && (
                <div>
                  <Label htmlFor='filter-author-input'>Auteur</Label>
                  <Select
                    id='filter-author-input'
                    isSearchable={true}
                    isClearable={true}
                    options={users}
                    value={users.find(u => u.value === filterAuthorId) || null}
                    onChange={handleFilterAuthorChange}
                    placeholder='Auteur'
                  />
                </div>
              )}
              <div>
                <Label htmlFor='filter-inter-inj'>Interventions uniquement avec prescriptions</Label>
                <div style={{ display: 'flex', alignItems: 'center', height: '38px' }}>
                  <SwitchDouble
                    labels={['Non', 'Oui']}
                    switchValue={filterPrescriptions}
                    onChange={() => setFilterPrescriptions(!filterPrescriptions)} />
                </div>
              </div>
              {!prescriptionsSchemaIsLoading && prescriptionsSchema && (
                <div>
                  <Label htmlFor='filter-prescription-schema-input'>Schéma de prescription</Label>
                  <Select
                    id='filter-prescription-schema-input'
                    isSearchable={true}
                    isClearable={true}
                    options={prescriptionsSchema}
                    value={
                      prescriptionsSchema.find(u => u.value === filterPrescriptionSchema) ||
                      (filterPrescriptionSchemaOthers.length > 0 && prescriptionsSchema.find(u => u.value === 'other')) ||
                      null
                    }
                    onChange={e => handleFilterPrescriptionSchemaChange(e)}
                    placeholder=''
                  />
                </div>
              )}
            </div>

            <div className='rowAction'>
              <Button type='button' className='warn' onClick={() => handleResetFilter()}>
                Réinitialiser les filtres
              </Button>
              <Button className='filled' type='submit'>
                Appliquer les filtres
              </Button>
            </div>
          </FilterBar>
        </SearchBar>
      </SearchBarCompo>

      <div>
        {payloadIsLoading ? (
          <LoadingSpinner className='center vh-50' />
        ) : (
          <>
            {payload === undefined ? (
              <>
                <div style={{ marginLeft: '1rem', marginTop: '1rem' }}>
                  <h3>Une erreur est survenue pendant la récupération des interventions.</h3>
                  <p>Veillez à disposer d'une connexion internet valide.</p>
                </div>
              </>
            ) : (
              <>
                <div style={{ marginBottom: '.5rem', marginTop: '1rem' }}>{pagination}</div>
                {payloadIsFetching ? (
                  <LoadingSpinner className='center vh-50' />
                ) : (
                  <>
                    <List
                      bilans={payload.bilans}
                      listingIdPerm='web:interventions_pui:listing:identite'
                      showPriority={syoUtils.hasPerm(currentUser, 'web:intervention:priorite')}
                      pui={true}
                      sortBilan={match.location.query.createdEntity || 'bilans'}
                      users={users}
                    />
                    <div>{pagination}</div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default PharmacyCompliance;
